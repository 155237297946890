import jwtDefaultConfig from './jwtDefaultConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => { 
        useToast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Something went wrong when loading the page",
          },
        })

        Promise.reject(error) 
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        useToast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Something went wrong when loading the page",
          },
        })

        // if (status === 401) {
        if (response && (response.status === 401 || response.status === 403)) {

          // If token has expired, remove data from local storage
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem('token_expiry_at')
          localStorage.removeItem('role')

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Redirect to login page
          // this.$router.push({ name: 'auth-login' })

          window.location.href = "/";

          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     // Update accessToken in localStorage
          //     this.setToken(r.data.access_token)
          //     this.setRefreshToken(r.data.refresh_token)

          //     this.onAccessTokenFetched(r.data.access_token)
          //   })
          // }
          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // Make sure to assign accessToken according to your response.
          //     // Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axiosIns(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        }
        // else
        // if(response.status === 500) {
        //   this.toast({
        //     component: ToastificationContent,
        //     position: 'top-right',
        //     props: {
        //       title: `Error`,
        //       icon: 'CoffeeIcon',
        //       variant: 'danger',
        //       text: "Something went wrong when loading the page",
        //     },
        //   })
        // }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    })
  }
}
